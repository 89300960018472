<template>
    <div id="contact_us" class="container contact_us_div">
        <div class="row m-0">
            <div class="col-md-6 col-12 text-center text-white" style="padding: 20px; border: 1px solid #359bf4;">
                <p class="text-center text-blue-dark" style="padding-bottom: 20px; font-size: 1.75rem; font-weight: 500;">CONTACT US</p>
                <p class="pb-3" style="line-height: 35px; font-size: 1.5rem;">
                    <i class="fas fa-phone-alt fa-1x text-white-50 mr-2"></i>
                    <span v-if="getCompanyInfo.website_content.contact_number">{{getCompanyInfo.website_content.contact_number}}</span>
                    <span v-else>+88 01799 005747</span>
                    <br/>
                    <i class="fas fa-envelope fa-1x text-white-50 mr-2"></i>
                    <span v-if="getCompanyInfo.website_content.contact_email">{{getCompanyInfo.website_content.contact_email}}</span>
                    <span v-else>alamin.chokrojan@gmail.com</span>
                </p>
            </div>
            <div class="col-md-6 col-12 text-center text-white" style="padding: 20px; border: 1px solid #359bf4;">
                <p class="text-center text-blue-dark" style="padding-bottom: 20px; font-size: 1.75rem; font-weight: 500;">OFFICE ADDRESS</p>
                <p class="pb-3" style="line-height: 35px; font-size: 1.5rem;">
                    <i class="fas fa-map-marker-alt fa-1x text-white-50 mr-2"></i>
                    <span v-if="getCompanyInfo.website_content.contact_address" v-html="getCompanyInfo.website_content.contact_address"></span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    export default {
        name: 'ContactUs',
        computed:{
            ...mapGetters(['getCompanyInfo'])
        }
    }
</script>

<style scoped>
.contact_us_div{
    padding-top: 80px;
    padding-bottom: 80px;
}
@media screen and (max-width: 768px) {
    .contact_us_div{
        padding-top: 30px;
        padding-bottom: 30px;
    }
}
</style>
